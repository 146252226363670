// router.js
import { createRouter, createWebHistory } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
import Forgot from "./components/Forgot.vue";
import Reset from "./components/Reset.vue";
import SearchResult from "./components/SearchResult.vue";
import ColorInfo from "./components/ColorInfo.vue";
import Error from "./components/Error.vue"; // Import your ErrorComponent

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: Home, meta: { requiresAuth: true } },
    { path: "/login", component: Login },
    { path: "/register", component: Register },
    { path: "/forgot", component: Forgot },
    { path: "/reset/:token", component: Reset },
    {
      path: "/search/:searchQuery",
      name: "SearchResult",
      component: SearchResult,
      props: (route) => ({
        searchQuery: route.params.searchQuery,
      }),
    },
    {
      path: "/color/:colorName",
      name: "ColorInfo",
      component: ColorInfo,
      props: (route) => ({
        searchResults: route.query.searchResults
          ? JSON.parse(route.query.searchResults)
          : [],
      }),
    },
    // Define an error route here
    { path: "/error", component: Error},
  ],
});

export default router;
