<template>
  <form @submit.prevent="handleSubmit">
    <div class="forgot-wrapper">
      <div v-if="message" class="alert alert-success" role="alert">
        {{ message }}
      </div>
      <error v-if="error" :error="error" />

      <h3>Forgot Password</h3>

      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" id="email" class="form-control" v-model="email" placeholder="Email" required />
      </div>

      <button type="submit" class="btn btn-primary btn-block">Submit</button>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import Error from './Error.vue';


/* eslint-disable */

export default {
  name: 'Forgot',
  components: {
    Error,
  },
  data() {
    return {
      email: '',
      message: '',
      error: '',
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const response = await axios.post('/forgot', {
          email: this.email,
        });

        if (response.status === 200) {
          this.message = 'The email was sent!';
          this.error = '';
        } else {
          this.error = 'Error occurred!';
          this.message = '';
        }
      } catch (error) {
        this.error = 'Error occurred!';
        this.message = '';
      }
    },
  },
};
</script>

<style scoped>
.forgot-wrapper {
  max-width: 400px;
  margin: 100px auto;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  border-radius: 15px;
  text-align: left;
}

.forgot-wrapper h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 15px;
  line-height: 50px;
}

label {
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.btn-primary {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f37f20;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.btn-primary:hover {
  background-color: #000;
}
</style>
