<template>
  <div id="app">
    <Header />
    <div class="auth-wrapper">
      <router-view :key="$route.fullPath" />
      <div class="search-container" :class="{'sticky': $route.name === 'SearchResult'}" ref="searchContainer">
        <!-- <input type="text" placeholder="Search..." /> -->
      </div>
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue';
// import router from './router';

/* eslint-disable */


export default {
  name: 'App',
  components: {
    Header,
  },
//   router,
//   created() {
//     // Check for user information in local storage when the app starts
//     const savedUser = JSON.parse(localStorage.getItem('user'));
//     if (savedUser) {
//       // If a user is found in local storage, update the Vuex store
//       this.$store.commit('SET_USER', savedUser);

//       // If a user is found, fetch colors (you can adjust this condition based on your application logic)
//       this.fetchColorsAfterLogin(savedUser);
//     }
//   },
//   methods: {
//   async fetchColorsAfterLogin(user) {
//     // Here you can fetch colors after the user information is available
//     try {
//       // Get the user token from the Vuex store
//       const token = this.$store.getters.userToken;

//       // Fetch colors only if the user is logged in and token is available
//       if (token) {
//         // Call the action to fetch colors
//         await this.$store.dispatch('colors/fetchColors', { searchQuery: this.$store.getters.searchQuery, userToken: token });
//         const searchResults = this.$store.getters.colors;

//         // Emit the search results to the parent component (SearchResult) or use them as needed
//         this.$emit('search-results', {
//           searchQuery: this.$store.getters.searchQuery,
//           colors: searchResults,
//         });
//       }
//     } catch (error) {
//       console.error('Error fetching colors:', error);
//     }
//   },
// },
};
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
}

body {
  /* background: #f37f20 !important; */
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  text-decoration: none !important;
}

h1, h2, h3, h4, h5, h6, label, span {
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}

body, html, #app, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}

#app {
  text-align: center;
}

.navbar-light {
  background-color: #000000;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  /* display: flex; */
  justify-content: center;
  align-items: flex-start;
  min-height:100vh;
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
}

.home {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.auth-inner {
  width: 100%;
  max-width: 450px;
  margin: auto;
  background: #ffffff;
  /* box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2); */
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #f37f20;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
  padding-top: 50px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #f37f20;
  margin: 0;
}

.forgot-password a {
  color: #f37f20;
}

@media (max-width: 991.98px) {
  /* Styles for mobile view */
  .navbar-nav {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .nav-item {
    margin-bottom: 10px;
  }

  .auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  /* padding-bottom: 50px; */
  /* padding-top: 100px; */
}
}
</style>
