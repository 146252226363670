import axios from 'axios';

const axiosWithCors = axios.create({
  baseURL: 'https://api.tintatek.com/',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to set the Authorization header with the Bearer token
const setBearerToken = (token) => {
  axiosWithCors.defaults.headers['Authorization'] = `Bearer ${token}`;
};

// Function to store the Bearer token in localStorage
const storeBearerToken = (token) => {
  localStorage.setItem('bearerToken', token);
  setBearerToken(token);
};

// Function to handle user logout and clear the Bearer token
const clearBearerToken = () => {
  localStorage.removeItem('bearerToken');
  delete axiosWithCors.defaults.headers.common['Authorization'];
};

// Axios request interceptor to add the Bearer token to the request header
axiosWithCors.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('bearerToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axiosWithCors, storeBearerToken, clearBearerToken };
