<template>
  <div class="search-container">
    <div class="search-input-container">
      <input
        type="text"
        class="search-input"
        v-model="query"
        placeholder="Search by color..."
        @keydown.enter="searchColor"
      />
      <button class="search-button" @click="searchColor">Search</button>
    </div>
    <div v-if="noMatchingColor" class="no-matching-color">No Matching Color Found</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      query: '',
      noMatchingColor: false,
    };
  },
  computed: {
    ...mapGetters('colors', ['colors']),
    lowercaseSearchQuery() {
      return this.query.trim().toLowerCase();
    },
  },
  methods: {
    ...mapActions('colors', ['fetchColorsFromAPI']), // Use the 'fetchColors' action directly

    async searchColor() {
      try {
        await this.fetchColorsFromAPI({ searchQuery: this.query }); // Use the 'fetchColorsFromAPI' action
        const searchResults = this.colors.filter((color) =>
          color.name.toLowerCase().includes(this.lowercaseSearchQuery)
        );

        this.$emit('search-results', {
          searchQuery: this.query,
          colors: searchResults,
        });

        this.noMatchingColor = searchResults.length === 0;
      } catch (error) {
        console.error('Error fetching colors:', error);
      }
    },
  },
};
</script>



<style scoped>
.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  width: 100%;
  max-width: 800px;
}

.search-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
}

.search-input {
  flex: 1;
  height: 44px;
  padding: 10px 16px;
  font-size: 16px;
  border: 2px solid #f37f20;
  border-radius: 24px;
  box-shadow: 0 1px 6px rgba(243, 127, 32, 1);
  background-color: #f1f3f4;
  margin-right: 10px;
}

.search-input:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(243, 127, 32, 0.25);
}

.search-button {
  height: 36px;
  padding: 0 16px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #dcdcdc;
  color: #4d4f52;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-button:hover {
  background-color: #f37f20;
  color: #fff;
}

.search-button:focus {
  outline: none;
}

.no-matching-color {
  margin-top: 10px;
  color: red;
  font-weight: bold;
}

/* Media Query for Mobile Devices */
/* @media (max-width: 767px) {
  .search-input-container {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 20px;
  }

  .search-input {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .search-button {
    width: 100%;
  }
} */
</style>