import { createStore } from "vuex";
import colorsModule from "./colors";
import { axiosWithCors } from "./axios"; // Import the axiosWithCors instance

/* eslint-disable */

const store = createStore({
  state: {
    user: JSON.parse(sessionStorage.getItem("user")) || null,
    // userToken: null, // Add userToken to store the user token
    bearerToken: localStorage.getItem('BearerToken') || null, // Add bearerToken to store the bearer token
    searchQuery: "",
    searchResults: [],
    lastSearchQuery: "", // Add lastSearchQuery to store the last search query
    lastSearchResults: [], // Add lastSearchResults to store the last search results
    colorInfo: null,
    loading: false,
  },
  getters: {
    user: (state) => state.user,
    searchQuery: (state) => state.searchQuery,
    searchResults: (state) => state.searchResults,
    isLoggedIn: (state) => state.user !== null, // Add a new getter to check if the user is logged in
    bearerToken: (state) => state.bearerToken, // Update the getter to get the bearer token directly from the state
    // userToken: (state) => (state.user ? state.user.token : null), // Add a new getter to get the user token directly from the state
    colorInfo: (state) => state.colorInfo,
    loading: (state) => state.loading,
  },
  actions: {
    async fetchUser({ commit }, { username, password }) {
      try {
        const response = await axiosWithCors.post('/api/auth/login', {
          username: username,
          password: password,
        });

        const fetchedUser = response.data;
        // console.log('Fetched user:', fetchedUser);
        commit('SET_USER', fetchedUser);
        commit('SET_BEARER_TOKEN', fetchedUser.bearer_token);

        // Set the user token and bearer token in the state
        // commit('SET_USER_TOKEN', fetchedUser.token);


        // Save user token and bearer token in the session storage
        sessionStorage.setItem('user', JSON.stringify(fetchedUser));
        // sessionStorage.setItem('userToken', fetchedUser.token);
        sessionStorage.setItem('BearerToken', fetchedUser.bearer_token);

        return response; // Return true to indicate login success
      } catch (error) {
        if (error.response && error.response.status === 401) {
          commit('SET_USER', null);
          // commit('SET_USER_TOKEN', null);
          commit('SET_BEARER_TOKEN', null);
          sessionStorage.removeItem('user');
          // sessionStorage.removeItem('userToken');
          sessionStorage.removeItem('bearerToken');
          return null; // Return false to indicate login failure
        } else {
          throw error;
        }
      }
    },
    async logout({ commit }) {
      // Clear the user data and tokens from the store and localStorage
      commit('SET_USER', null);
      // commit('SET_USER_TOKEN', null);
      commit('SET_BEARER_TOKEN', null);
      sessionStorage.removeItem('user'); // Remove from session storage
      // sessionStorage.removeItem('userToken'); // Remove from session storage
      sessionStorage.removeItem('bearerToken'); // Remove from session storage
    },
    async fetchColorInfo({ commit, state }, colorName) {
      // Check if the color information is already available in the store
      if (state.colorInfo && state.colorInfo.name === colorName) {
        return state.colorInfo;
      }
      try {
        const formData = new URLSearchParams();
        formData.append('search', colorName);
        formData.append('BearerToken', localStorage.getItem('BearerToken'));

        const url = 'https://api.tintatek.com/api/search-color';

        const response = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer `+localStorage.getItem('AuthToken'),
          },
        });

        // Handle the response data and commit the fetched color info to the state
        const colorInfo = response.data.data.colours[0];
        commit('SET_COLOR_INFO', colorInfo);

        return color;
      } catch (error) {
        // Handle any errors
        console.error('Error fetching color info:', error);
        return null;
      }
    },
  },
  mutations: {
    SET_COLOR_INFO(state, color) {
      state.colorInfo = color;
    },
    SET_USER(state, user) {
      state.user = user !== null ? user : null;
    },
    // SET_USER_TOKEN(state, token) {
    //   state.userToken = token;
    // },
    SET_BEARER_TOKEN(state, token) {
      state.bearerToken = token;
      localStorage.setItem('bearerToken', token); // Also, store it in localStorage
    },
    SET_SEARCH_QUERY(state, query) {
      state.searchQuery = query;
    },
    SET_SEARCH_RESULTS(state, results) {
      state.searchResults = results;
    },
    SET_LOADING(state, isLoading) {
      state.loading = isLoading;
    },
  },
  
  modules: {
    colors: colorsModule, // Register the 'colors' module
  },
});

export default store;
