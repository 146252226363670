<template>
  <div class="alert alert-danger" role="alert">
    {{ error }}
  </div>
</template>

<script>
/* eslint-disable */ 
export default {
  name: 'Error',
  props: {
    error: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.alert {
  margin-bottom: 20px;
}
</style>