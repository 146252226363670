<template>
  <form @submit.prevent="handleSubmit">
    <div class="auth-wrapper">
      <div class="auth-inner">
        <!-- Use the error prop to pass the error message to the Error component -->
        <error v-if="error" :error="error" />

<!-- Success message and login link wrapped in a separate div -->
<div v-if="registrationStatus === 'success'" class="success-container">
          <div class="success-message">
            {{ successMessage }}
          </div>
          <router-link to="/login" class="login-link">Login</router-link>
        </div>

        <h3>Sign Up</h3>

        <div class="form-group">
          <label class="form-label">Email</label>
          <input
            type="email"
            class="form-control"
            v-model="email"
            placeholder="Email"
          />
          <!-- Display email error as a pop-up -->
          <div v-if="!isValidEmail" class="error-popup">
            <div class="error-message">Invalid email format</div>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label">Username</label>
          <input
            type="text"
            class="form-control"
            v-model="username"
            placeholder="Username"
          />
          <!-- Display username error as a pop-up -->
          <div v-if="!isValidUsername" class="error-popup">
            <div class="error-message">Username should have at least 4 characters</div>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label">Password</label>
          <input
            type="password"
            class="form-control"
            v-model="password"
            placeholder="Password"
          />
          <!-- Display password error as a pop-up -->
          <div v-if="!isValidPassword" class="error-popup">
            <div class="error-message">Password should have at least 8 characters</div>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label">Confirm Password</label>
          <input
            type="password"
            class="form-control"
            v-model="confirmPassword"
            placeholder="Confirm Password"
          />
          <!-- Display confirmPassword error as a pop-up -->
          <div v-if="!isValidConfirmPassword" class="error-popup">
            <div class="error-message">Passwords do not match</div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary btn-block">Sign Up</button>



      </div>
    </div>
  </form>
</template>

<script>
/* eslint-disable */
import Error from "./Error.vue";
import { mapActions } from "vuex";
import { axiosWithCors } from "../axios";

export default {
  name: "Register",
  components: {
    Error,
  },
  data() {
    return {
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      error: "",
      registrationStatus: null, // To track the registration status
      successMessage: "", // To store the success message
    };
  },
  methods: {
    ...mapActions(["fetchUser"]), // Remove the "user" namespace if not needed

    async handleSubmit() {
  try {
    // console.log('Before registration request');
    // Reset the user state before registration
    this.$store.commit('SET_USER', null);

    const headers = {
      "Content-Type": "application/json",
    };
    const userData = {
      email: this.email,
      username: this.username,
      password: this.password,
      ConfirmPassword: this.confirmPassword,
    };

    const response = await axiosWithCors.post(
      "https://api.tintatek.com/api/auth/register",
      userData,
      { headers }
    );

    // console.log('After registration request');

    if (response.status === 200 && response.data && response.data.message === "Account Created. Please Verify your email.") {
      // Registration was successful, show a message to verify email
      this.registrationStatus = 'success';
      this.successMessage = "Registration successful. Please check your email for a verification link.";
      // Clear the error message
      this.error = '';
    } else if (response.status === 422) {
      // Registration failed due to validation errors
      // Extract the error messages from the `errors` array
      if (response.data && response.data.errors) {
        const errorMessages = response.data.errors;
        // Set `this.error` to display the error messages as a string
        this.error = errorMessages.join(', ');
      } else {
        // Unexpected error response format
        this.error = "An error occurred during registration.";
      }
    } else {
      // Other unexpected response status
      this.error = "An error occurred during registration.";
    }
  } catch (e) {
    // Handle other types of errors if needed
    console.error('Registration error:', e);

    // Check if the error has a response containing an error message
    if (e.response && e.response.data && e.response.data.errors) {
      // Extract the error messages from the `errors` array
      const errorMessages = e.response.data.errors;
      
      // Set `this.error` to display the error messages as a string
      this.error = errorMessages.join(', ');
    } else if (e.response && e.response.data && e.response.data.message) {
      this.error = e.response.data.message;
    } else {
      this.error = 'An error occurred during registration.';
    }

    // Clear registration status on error
    this.registrationStatus = null;
  }
},

  },
  computed: {
    isValidEmail() {
      // Implement your email validation logic here
      // For example, you can use a regular expression to validate the email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    },
    isValidUsername() {
      // Implement your username validation logic here
      return this.username.length >= 4; // Example: Username should have at least 4 characters
    },
    isValidPassword() {
      // Implement your password validation logic here
      return this.password.length >= 8; // Example: Password should have at least 8 characters
    },
    isValidConfirmPassword() {
      // Implement your confirm password validation logic here
      return this.confirmPassword === this.password;
    },
  },
};
</script>


<style scoped>
.auth-wrapper {
  display: flex;
  justify-content: flex-start; /* Align the form to the left */
  min-height: calc(100vh - 56px); /* Subtracting the header height */
  padding: 20px;
  background-color: #f0f0f0; /* Background color for the entire form */
}

.auth-inner {
  width: 100%;
  max-width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
}

.auth-inner h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
  color: #333; /* Text color for the heading */
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
  color: #555; /* Text color for labels */
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc; /* Border color for input fields */
  border-radius: 5px;
  font-size: 16px;
}

.form-control:focus {
  outline: none;
  border-color: #f37f20; /* Border color when input is focused */
}

.error-message {
  font-size: 14px;
  color: #ff0000;
  margin-top: 5px;
  /* Remove the position and top properties to prevent error messages from overlapping input fields */
}

.success-container {
  background-color: #dbf3dc; /* Green background color for success */
  text-align: center; /* Center the text within the container */
  padding: 10px; /* Add some padding for spacing */
  border-radius: 5px; /* Rounded corners for the container */
  margin-top: 10px; /* Add some top margin to separate it from the form */
}

.success-message {
  font-size: 18px;
  color: #4caf50; /* Green text color */
  text-align: center;
  margin-top: 10px;
}

.btn-primary {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 25px;
  background-color: #f37f20;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #d9601e; /* Background color on hover */
}

/* Media queries for responsiveness */
@media (max-width: 991.98px) {
  .auth-wrapper {
    padding-top: 100px; /* Increase the top padding on smaller screens */
  }
}
</style>
