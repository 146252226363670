<template>
  <form @submit.prevent="handleSubmit">
    <div class="reset">
      <h3>Reset Password</h3>

      <div class="form-group">
        <label>Password</label>
        <input type="password" class="form-control" v-model="password" placeholder="Password" />
      </div>

      <div class="form-group">
        <label>Password Confirm</label>
        <input type="password" class="form-control" v-model="password_confirm" placeholder="Password Confirm" />
      </div>

      <button class="btn btn-primary btn-block">Submit</button>
    </div>
  </form>
</template>

<script>
import axios from 'axios';

/* eslint-disable */

export default {
  name: 'Reset',
  data() {
    return {
      password: '',
      password_confirm: '',
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const response = await axios.post('reset', {
          password: this.password,
          password_confirm: this.password_confirm,
          token: this.$route.params.token,
        });

        // console.log(response);
        this.$router.push('/login');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.reset {
  max-width: 400px;
  margin: 100px auto;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  border-radius: 15px;
  text-align: left;
}

.reset h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 15px;
  line-height: 50px;
}

.form-control {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.btn-primary {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f37f20;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.btn-primary:hover {
  background-color: #000;
}
</style>


