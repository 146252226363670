<template>
  <header>
    <nav :class="{'navbar': !isHomePage, 'navbar-expand': !isHomePage, 'navbar-light': !isHomePage, 'fixed-top': !isHomePage, 'sticky-search-container': isSearchResultPage}">
      <div class="container">
        <div class="header-left">
          <router-link to="/" class="navbar-brand">
            <img src="@/assets/Tinta-White.png" alt="Tintatek" class="logo" />
          </router-link>
        </div>

        <!-- Show the search container only on the SearchResult page -->
        <div class="sticky-search-container" v-if="isSearchResultPage">
          <div class="search-form">
            <input
              type="text"
              class="search-input"
              v-model="query"
              placeholder="Search by color..."
              @keydown.enter="searchColor"
            />
            <button class="search-button" @click="searchColor">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>

        <div class="header-right">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item" v-if="!isLoggedIn">
              <router-link to="/login" class="nav-link">
                <span>Login </span>
                <i class="fas fa-sign-in-alt"></i>
              </router-link>
            </li>
            <li class="nav-item" v-if="isLoggedIn">
              <a href="javascript:void(0)" class="nav-link">
                <span>{{ user?.username }} {{ user?.last_name }}</span>
                <i class="fas fa-user"></i>
              </a>
            </li>
            <li class="nav-item" v-if="isLoggedIn">
              <a href="javascript:void(0)" @click="handleLogout" class="nav-link">
                <span>Logout </span>
                <i class="fas fa-sign-out-alt"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Header',
  props: {
    isHomePage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('colors', ['colors']),
    ...mapGetters(['user']),
    isLoggedIn() {
      return this.user && this.user.token && this.user.token.trim() !== '';
    },
    isSearchResultPage() {
      return this.$route.name === 'SearchResult';
    },
  },
  data() {
    return {
      query: '',
    };
  },
  methods: {
   ...mapActions('colors', ['fetchColors']),
   ...mapActions(['logout']),

    async searchColor() {
      try {
        this.$store.dispatch('colors/fetchColors', { searchQuery: this.query });
        const lowercaseSearchQuery = this.query.toLowerCase();
        const searchResults = this.colors.filter((color) =>
          color.name.toLowerCase().includes(lowercaseSearchQuery)
        );
        this.$emit('search-results', {
          searchQuery: this.query,
          colors: searchResults,
        });
        this.$router.push({
          name: 'SearchResult',
          params: { searchQuery: this.query },
        });
      } catch (error) {
        console.error('Error fetching colors:', error);
      }
    },

    async handleLogout() {
      try {
        await this.$store.dispatch('logout');
        this.$router.push('/');
      } catch (error) {
        console.error('Error logging out:', error);
      }
    },
  },
};
</script>





<style scoped>
.header-left {
  display: flex;
  align-items: center;
}

.logo {
    width: 100px; /* Adjust the width as needed */
    height: auto; /* Allow the height to adjust automatically */
    margin-right: 10px;
  }

.header-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.navbar-brand {
  font-weight: bold;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.nav-item {
  margin-left: 10px;
  /* padding-top: 10px; */
}

.nav-link {
  color: #ffffff;
  text-decoration: none;
}


.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  /* padding-top: 15px; */
  /* padding-bottom: 10px; */
  /* width: 100%; */
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  /* transition: box-shadow 0.3s; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.search-form {
  display: flex;
  align-items: center;
  /* justify-content: center;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto; */
}

.search-input {
  flex: 1;
  /* min-width: 200px; */
  height: 40px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-right: 10px;
  /* max-width: 100%; */
  /* margin-left: 50px; */
  /* width: 100%; */
}

.search-button {
  /* float: right ; */
  height: 40px;
  width: 40px;
  padding: 0;
  font-size: 20px;
  border: none;
  border-radius: 20px;
  background-color: transparent;
  color: #f37f20;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sticky-search-container {
  display: flex;
  align-items: center;
  /* margin-left: 10px; */
  /* width: 100%; */
}

.search-form {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .logo {
  width: 65px;
  height: 25px;
  margin-right: 10px;
}

.nav-item{
font-size: 11px;

}

.search-input {
width: 180px;
}

}

/* 
@media (max-width: 767px) {
  .sticky-search-container {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .search-form {
    flex-direction: column;
    align-items: stretch;
  }

  .search-input {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .search-button {
    width: 100%;
  }
} */


</style>
