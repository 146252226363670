<template>
  <form @submit.prevent="handleSubmit">
    <div class="auth-wrapper">
      <div class="auth-inner">
        <error v-if="error" :error="error" />
        <h3>Login</h3>
        <div class="form-group">
          <label>Username</label>
          <input type="text" class="form-control" v-model="username" placeholder="Username" />
          <!-- Display error message for empty username -->
          <div v-if="!username" class="error-message">Please enter your username.</div>
          <!-- Display username validation message -->
          <div v-if="!isValidUsername" class="error-message"></div>
        </div>

        <div class="form-group">
          <label>Password</label>
          <input type="password" class="form-control" v-model="password" placeholder="Password" />
          <!-- Display error message for empty password -->
          <div v-if="!password" class="error-message">Please enter your password.</div>
          <!-- Display password validation message -->
          <div v-if="!isValidPassword" class="error-message"></div>
        </div>

        <button class="btn btn-primary" :disabled="!isValidForm">Login</button>

        <!-- Display error message for login failure -->
        <!-- <div v-if="error" class="error-message">{{ error }}</div> -->

        <p class="forgot-password text-right">
          <router-link to="/forgot">Forgot Password?</router-link>
        </p>
        <hr>
        <p class="register-link">Haven't registered?
           <br><router-link to="/register">Sign up now</router-link></p>
      </div>
    </div>
  </form>
</template>

<script>
/* eslint-disable */
import Error from './Error.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Login',
  components: {
    Error,
  },
  data() {
    return {
      username: '',
      password: '',
      error: '', // Store the error message from the backend
    };
  },
  computed: {
    ...mapGetters(['searchQuery', 'bearerToken']),
    isValidUsername() {
      // Implement your username validation logic here
      return this.username.length >= 4; // Example: Username should have at least 4 characters
    },
    isValidPassword() {
      // Implement your password validation logic here
      return this.password.length >= 8; // Example: Password should have at least 8 characters
    },
    isValidForm() {
      // Check if both username and password are valid
      return this.isValidUsername && this.isValidPassword; // Remove the extra set of parentheses
    },
  },
  methods: {
    ...mapActions({
      fetchUser: 'fetchUser', // Make sure 'fetchUser' action is correctly defined in your Vuex store
      fetchColors: 'colors/fetchColorsFromAPI', // Use namespaced action type
    }),
    async handleSubmit() {
      // console.log('handleSubmit called');
      try {
        // Reset the user state before login
        this.$store.commit('SET_USER', null);

        const response = await this.fetchUser({
          username: this.username,
          password: this.password,
        });

        // Log the API response for debugging

        // Check if login was successful
        if (response && response.status === 200 && response.data && response.data.token && response.data.bearer_token) {
  // Use the namespaced mutation to set the user data
  // this.$store.commit('SET_USER', response.data);

  // Save the BearerToken to localStorage or wherever you store it
  localStorage.setItem('BearerToken', response.data.bearer_token);
  localStorage.setItem('AuthToken', response.data.token);

  // console.log("AuthToken: "+" "+response.data.token);
  // console.log("Bearer Token: "+" "+response.data.token);

  // Diagnostic Step 1: Check BearerToken storage
  // console.log('BearerToken in localStorage:', localStorage.getItem('BearerToken'));
  // console.log('AuthToken in localStorage:', localStorage.getItem('AuthToken'));

  // console.log('BearerToken from Vuex:', this.bearerToken);

  // ** Wait for the bearerToken to be set **
  // await this.$store.dispatch('colors/fetchColorsFromAPI', { queryParams: this.searchQuery });

  // Redirect to the home page
  this.$router.push('/');
} else if (response && response.status === 401 && response.data && response.data.message === 'Please verify your email address before logging in.') {
  // Handle the case where the backend responds with a message to verify email
  // console.log('Response status:', response.status);
  // console.log('Response message:', response.data.message);
  this.error = 'Please verify your email address before logging in.';
} else {
  // The error message is now coming from the backend response
  if (response && response.data && response.data.message && response.data.message) {
    this.error = response.data.message; // Display the first message from the array
  } else {
    this.error = 'Please verify your email address before logging in.';
  }
}
      } catch (e) {
        // Handle other types of errors if needed
        // console.error('Login error:', e);

        // Check if the error has a response containing an error message
        if (e.response && e.response.data && e.response.data.message && e.response.data.message[0]) {
          if (Array.isArray(e.response.data.message)) {
            this.error = e.response.data.message.join(', '); // Display the error message
          } else {
            this.error = e.response.data.message; // Display a single error message
          }
        } else {
          this.error = 'An error occurred during login.';
        }
      }
    },
    handleLogout() {
      this.$store.dispatch('logout'); // Dispatch the logout action to clear user data
      this.clearBearerToken(); // Clear Bearer token from local storage and Axios headers
      // Redirect to login
      this.$router.push({ name: 'login' });
    },
    clearBearerToken() {
      localStorage.removeItem('BearerToken'); // Clear token from local storage
      this.$axios.defaults.headers.common['Authorization'] = ''; // Clear token from Axios headers
    },
  },
};
</script>





<style scoped>
.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 56px);
  text-align: left;
  padding-top: 80px;
}

.auth-inner {
  width: 100%;
  max-width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 25px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #f37f20;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.form-group {
  line-height: 50px;
}

.btn-primary {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 25px;
  background-color: #f37f20;
  color: #fff;
  border: none;
  transition: transform 0.3s ease;
  width: 100%;
}

.btn-primary:hover {
  background-color: #000;
  color: #fff;
}

.register-link {
  text-align: center;
  margin-top: 25px;
}

.register-link router-link {
  color: #f37f20;
  text-decoration: none;
}

.register-link router-link:hover {
  text-decoration: underline;
}




@media (min-width: 992px) {
  .auth-wrapper {
    padding-top: 100px;
  }
}
</style>
