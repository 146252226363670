<template>
  <div class="search-results">
    <!-- <Header /> -->
    <div class="search-result-container">
      <div class="search-info">
        <h3 v-if="searchQuery">Search results for "{{ searchQuery }}"</h3>
        <p v-if="hasResults">{{ filteredColors.length }} results found</p>
        <div class="color-list" v-if="hasResults">
          <router-link
            v-for="color in filteredColors"
            :key="color.id"
            :to="{ name: 'ColorInfo', params: { colorName: color.name }, query: { searchQuery } }"
            class="color-item"
          >
            <div class="color-item-border">
              <h6> {{ color.colourCode }} </h6>
              <div v-if="shouldDisplayBackgroundColor(color)" class="color-square" :style="colorBackgroundStyle(color)"></div>
              <div v-else class="empty-square"></div>
              <div class="color-info">
                <h5>{{ color.name }}</h5>
                <p v-if="color.category.length > 0 ">{{ color.category[0].name }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div v-if="loading" class="loading-bar">
        <div class="spinner"></div>
      </div>
      <div v-else-if="hasResults" class="no-matching color">
      <!-- Hide this div when there are colors -->
      </div>
      <div v-else class="no-matching-color">
        No matching colors found.
      </div>
     <!-- Pagination controls -->
     <!-- <div class="pagination">
        <button
          @click="changePage(-1)"
          :disabled="currentPage === 1"
          class="pagination-button"
        >
          Previous
        </button>
        <span class="pagination-page">{{ currentPage }}</span>
        <button
          @click="changePage(1)"
          :disabled="currentPage * pageSize >= filteredColors.length"
          class="pagination-button"
        >
          Next
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';
import Header from './Header.vue';
import axios from 'axios';

export default {
  name: 'SearchResult',
  components: {
    Header,
  },
  props: {
    searchQuery: {
      type: String,
      required: true,
    },
    searchResults: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      // currentPage: 1,
      // pageSize: 10,
    };
  },
  computed: {
    ...mapGetters('colors', ['colors']),
    // ...mapGetters(['userToken']),
    filteredColors() {
    const searchQuery = this.searchQuery.toLowerCase(); // Convert the search query to lowercase
    console.log(this.colors);
    return this.colors.filter((color) =>
      color.name.toLowerCase().includes(searchQuery) ||
      color.colourCode.toLowerCase().includes(searchQuery) // Compare color code in lowercase
    //   color.category.name.toLowerCase().includes(searchQuery) // Compare color brand in lowercase
    );
    // console.log('Filtered Colors:', filteredColors);
    // return filteredColors;
  },
    hasResults() {
      return this.filteredColors.length > 0;
    },
  },
  methods: {
    async fetchColorsFromAPI(token, searchQuery) {
      try {
        console.log('User token:', token);
        this.loading = true;

        const queryParams = new URLSearchParams({
          search: searchQuery,
          BearerToken: localStorage.getItem('BearerToken'), // Include the bearer token
        });

        const url = `https://api.tintatek.com/api/search-color?${queryParams.toString()}`;

        const response = await axios.post(url, null, {
          headers: {
            Authorization: `Bearer `+localStorage.getItem('AuthToken'),
          },
        });

        console.log('API Response:', response);
        console.log('API Status:', response.status);

        const colors = response.data.data.colours;
        this.$store.commit('colors/SET_COLORS', colors);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error('Error fetching colors. Status:', error?.response?.status);
        console.error('Error data:', error?.response?.data);
      }
    },
    shouldDisplayBackgroundColor(color) {
      const { redValue, greenValue, blueValue } = color;
      return redValue !== 0 || greenValue !== 0 || blueValue !== 0;
    },
    colorBackgroundStyle(color) {
      const { redValue, greenValue, blueValue } = color;
      return {
        backgroundColor: `rgb(${redValue}, ${greenValue}, ${blueValue})`,
      };
    },
  },
  async created() {
    console.log('SearchResult component created');
    console.log('Current route:', this.$route);
    // Call the fetchColorsFromAPI method to fetch colors when the component is created
    if (localStorage.getItem('BearerToken') && this.searchQuery !== '') {
      await this.fetchColorsFromAPI(localStorage.getItem('BearerToken'), this.searchQuery);
    }
  },
  // changePage(offset) {
  //   this.currentPage += offset;
  // }
};
</script>



<style scoped>

h5 {
  font-size: 18px;
  color: #000;
  padding-top: 10px;
}


p {
  color: #8d99ae;
}

h3 {
  font-size: 18px;
  text-align: left;
  padding-left: 25px;
  padding-top: 100px;
}


.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
}

.search-input {
  flex: 1;
  min-width: 200px;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-right: 10px;
}

.search-button {
  height: 40px;
  padding: 0 20px;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  background-color: #f37f20;
  color: #fff;
  cursor: pointer;
}

.color-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    padding: 10px;
    /* margin-top: 50px; */
  }

  .color-item {
    flex-direction: column;
    align-items: center;
    color: #333;
    text-decoration: none;
    /* Add min-width to avoid very narrow items on small screens */
    min-width: 150px;
  }


.color-item-border {
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  transition: box-shadow 0.1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-color: #fff 2px;;
}

.color-square {
  width: 150px; /* Updated to 150px to make the color square smaller */
  height: 150px; /* Updated to 150px to maintain aspect ratio */
  margin: auto;
  cursor: pointer;
  border-radius: 5px;
}

.color-info {
  text-align: center;
}

.no-matching-color {
  margin-top: 150px;
  color: red;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.sticky-search-container.is-active {
  position: fixed;
  top: 0;
}

.color-item-border:hover {
  box-shadow: 0 4px 8px #f37f20;
}

.empty-square {
  width: 150px; /* Adjust the width and height as needed */
  height: 150px;
  margin: auto;
  background-color: transparent;
  border-style: solid;
  border-color: #000; /* Set a background color for the empty square */
  border-radius: 5px;
}

.loading-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #f37f20;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pagination-button {
  margin: 0 10px;
  padding: 5px 10px;
  font-size: 16px;
  background-color: #f37f20;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Standardize color list for tablets and larger screens */
@media (min-width: 480px) {
    .color-list {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }

  /* Standardize color list for desktop and larger screens */
  @media (min-width: 768px) {
    .color-list {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }

  </style>







