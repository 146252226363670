<template>
  <div class="home">
    <!-- Pass the 'searchResults' prop to the Header component -->
    <Header :isHomePage="true" :searchResults="searchResults" />
    <div>
      <!-- <h3 v-if="isLoggedIn">Welcome, {{ user?.username }} {{ user?.last_name }}</h3>
      <h3 v-else>You are not logged in.</h3> -->
      <div class="search-container">
        <router-link to="/" class="navbar-brand">
          <img src="@/assets/Tinta.png" alt="Tintatek" class="logo" />
        </router-link>
        <div class="search-box">
          <search-form @search-results="handleSearchResults" />
        </div>
      </div>
      <!-- Add a conditional check to ensure searchResults.colors exists -->
      <router-view v-if="isSearchResultPage" :searchResults="searchResults" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchForm from './SearchForm.vue';
import Header from './Header.vue';

/* eslint-disable */


export default {
  name: 'Home',
  meta: {
    requiresAuth: true,
  },
  components: {
    Header,
    SearchForm,
  },
  computed: {
    ...mapGetters(['user']),
    // Add the 'searchResults' getter to access the 'searchResults' state from Vuex
    searchResults() {
      return this.$store.state.searchResults;
    },
     // Add the 'searchQuery' getter to access the 'searchQuery' state from Vuex
     searchQuery() {
      return this.$store.state.searchQuery;
    },
    isSearchResultPage() {
      return this.$route.name === 'SearchResult';
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    }
  },
  watch: {
    // Watch for changes to the 'user' state in Vuex
    isLoggedIn: {
      immediate: true, // Trigger the watch immediately when the component is created
      handler(isLoggedIn) {
        // 'newValue' is the updated 'user' state from Vuex
        // You can check if the user is logged in and perform any other actions here
        if (isLoggedIn) {
          // console.log('User is logged in.');
        } else {
          // console.log('User is not logged in.');
        }
      }
    }
  },
  methods: {
    async handleSearchResults(searchResults) {
      // Check if the user is logged in (you don't need mapGetters here)
      if (this.user) { // Directly access the user state using the computed property
        // Proceed to the SearchResult page
        this.$router.push({ name: 'SearchResult', params: { searchQuery: searchResults.searchQuery } });
      } else {
        // If the user is not logged in, redirect to the login page
        this.$router.push('/login');
      }
    },
  },
};
</script>






<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* padding-top: 120px; */
}

.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  width: 100%;
  max-width: 800px;
}

.logo {
  width: 400px;
  height: 170px;
  margin-bottom: 40px;
  margin-top: 60px;
}

.search-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .search-container {
    max-width: 100%;
    padding: 0 20px;
  }

  .logo {
    width: 300px;
    height: 120px;
  }

  .search-box {
    max-width: 100%;
    margin-top: 10px;
  }

  .search-input {
    font-size: 16px; /* Adjust the font size as desired */
  }

  .search-button {
    font-size: 14px; /* Adjust the font size as desired */
  }
}

@media (max-width: 480px) {
  .search-container {
    max-width: 100%;
    padding: 0 10px;
  }

  .search-box {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-input {
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px; /* Adjust the font size as desired */
  }

  .search-button {
    width: 100%;
    font-size: 14px; /* Adjust the font size as desired */
  }
}
</style>