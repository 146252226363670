import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './vuex';

const app = createApp(App);
app.use(store);
app.use(router);

// Do not retrieve the user data from session storage and set it in the Vuex store here
// The user data will be managed by the Vuex store and retrieved from the session storage when needed

app.mount('#app');
