<template>
  <div class="color-info">
    <h3>Code: {{ color.colourCode }}</h3>
    <h2>{{ color.name }}</h2>
    <div v-if="shouldDisplayBackgroundColor" class="color-square" :style="{ backgroundColor: `rgb(${color.redValue}, ${color.greenValue}, ${color.blueValue})` }"></div>
    <div v-else class="empty-square"></div>
    <h3>Recommended Primer ({{ primerFormulas.length }})</h3>
    <table>
      <thead>
        <tr>
          <th>Primer Name</th>
          <th>Primer Color</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="primerFormula in primerFormulas" :key="primerFormula.id">
          <td>{{ primerFormula.component.productCode }}</td>
          <td>{{ primerFormula.component.name }}</td>
        </tr>
      </tbody>
    </table>
    <iframe style="padding-top: 50px;"
      width="100%"
      height="460"
      :src="videoUrl"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>

    <h3>Color Formula</h3>
    <table>
      <thead>
        <tr>
          <th>Steps</th>
          <th>Code</th>
          <th>Tinter</th>
          <th>Measurement (g)</th>
          <th>Remarks</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <td>0</td>
        <td>RB-001</td>
        <td>Tintatek Clear Binder</td>
        <td>40</td>
        <td>Add the suggested amount to the mix.</td>
      </tr>
        <tr v-for="(formula, index) in colorFormulas" :key="formula.id">
          <td>{{ index + 1 }}</td> <!-- Start sequence from 1 -->
          <td>{{ formula.component.productCode }}</td>
          <td>{{ formula.component.name }}</td>
          <td>{{ formula.amount }}</td>
          <td v-if="formula.remark">{{ formula.remark }}</td>
          <td v-else>-</td>
        </tr>
      </tbody>
    </table>

<br>
     <!-- Back to Search Results button -->
     <router-link :to="{ name: 'SearchResult', params: { searchQuery: $route.query.searchQuery }, query: { searchResults: JSON.stringify(searchResults) } }">
      Back to Search Results
    </router-link>
    
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'; // Import mapGetters from Vuex
import Header from './Header.vue';

export default {
  name: 'ColorInfo',
  props: {
    searchResults: Array,
  },
  components: {
    Header,
  },
  computed: {
    ...mapGetters('colors', ['colors']),
    color() {
      const colorName = this.$route.params.colorName;
      const color = this.colors.find((color) => color.name === colorName) || {};
      // Sort the color formulas by sequence in ascending order
      const sortedFormulas = color.formulas ? color.formulas.sort((a, b) => a.sequence - b.sequence) : [];
      return { ...color, formulas: sortedFormulas };
    },
    // New computed property to check if the background color should be displayed
    shouldDisplayBackgroundColor() {
      const { redValue, greenValue, blueValue } = this.color;
      return redValue !== 0 || greenValue !== 0 || blueValue !== 0;
    },
    colorFormulas() {
      // Filter the color formulas to get only the "Colour Tinter" formulas
      const colorTinterFormulas = this.color.formulas.filter(
        (formula) => formula.component.type === 'Colour Tinter'
      );

      // Sort the filtered formulas by sequence in ascending order
      const sortedFormulas = colorTinterFormulas.sort((a, b) => a.sequence - b.sequence);

      // Update the sequence for the sorted formulas to start from 1
      const updatedFormulas = sortedFormulas.map((formula, index) => ({
        ...formula,
        sequence: index + 1,
      }));

      return updatedFormulas;
    },

    primerFormulas() {
      // Filter the color formulas to get only the "Primer" formulas
      return this.color.formulas.filter((formula) => formula.component.type === 'Primer');
    },
    // Compute the URL for the video
    videoUrl() {
      // Replace with the URL of your video
      return "https://samurai2kaerosol.com/wp-content/uploads/2023/06/Tintatek-Demo-Tutorial.mp4";
    },
  },
};
</script>






<style scoped>
.color-info {
  padding: 20px;
  margin-top: 0;
  text-align: center;
}

.color-square {
  width: 200px;
  height: 200px;
  margin: 0 auto 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.color-info h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.color-info p {
  font-size: 16px;
  color: #888;
  margin-bottom: 20px;
}

.color-info h3 {
  margin-top: 20px;
  font-size: 18px;
}

.color-info table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.color-info th,
.color-info td {
  padding: 8px;
  border: 1px solid #ccc;
}

.color-info th {
  background-color: #f1f1f1;
}

.color-info a {
  display: inline-block;
  margin-top: 20px;
  color: #f37f20;
  text-decoration: none;
  font-weight: bold;
}

/* Style for the empty square */
.empty-square {
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
  width: 150px; /* Adjust the width and height as needed */
  height: 150px;
  margin: auto;
  background-color: transparent;
  border-style: solid;
  border-color: #000; /* Set a background color for the empty square */
  border-radius: 5px;
}



</style>
