/* eslint-disable */ 
import axios from 'axios';

const state = {
  colors: [], // Store your color information here
};

const getters = {
  colors: (state) => state.colors,
};

const actions = {
  async fetchColorsFromApi({ rootGetters }, colorName) {
    try {
      const queryParams = new URLSearchParams({
        search: colorName,
        BearerToken: localStorage.getItem('BearerToken'), // Include the bearer token
      });

      const url = `https://api.tintatek.com/api/search-color?${queryParams.toString()}`;

      const response = await axios.post(url, null, {
        headers: {
          Authorization: `Bearer `+localStorage.getItem('AuthToken'),
        },
      });

      // Handle the response data and return the fetched color
      const color = response.data.data.colours[0];
      return color;
    } catch (error) {
      // Handle any errors
      console.error('Error fetching color by name:', error);
      throw error; // Rethrow the error to handle it in the component
    }
  },
};

const mutations = {
  SET_COLORS(state, colors) {
    state.colors = colors;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};